import { FC } from 'react';
import { BlankRectangle } from '../components/SVGs';

const StickyOverlay: FC<{ children?: React.ReactNode | React.ReactNode[] }> = ({
  children,
}) => {
  return (
    <div className="grid grid-cols-[auto_1fr]">
      <div className="hidden col-start-1 row-start-1 laptop:block">
        <div className="sticky top-[calc(100vh-80px)] pl-32 z-40">
          <BlankRectangle />
        </div>
      </div>
      <div className="col-span-2 col-start-1 row-start-1">{children}</div>
    </div>
  );
};

export default StickyOverlay;
