import { s, c } from '../../val.config';
import { themeSchema } from '../schema/theme.val';

export const schema = s.object({
  theme: themeSchema,
  title: s.string(),
  metadataTitle: s.string(),
  metadataDescription: s.string(),
  navLinks: s.array(s.object({ title: s.string(), url: s.string().raw() })),
  contactLinkTitle: s.string(),
  hero: s.object({
    header: s.string(),
    intro: s.string(),
    pictureStack: s.array(s.image()),
  }),
});

export default c.define('/content/pages/home.val.ts', schema, {
  theme: `dark`,
  title: 'Spesialister på digital produktutvikling',
  metadataTitle: 'Spesialister på digital produktutvikling',
  metadataDescription:
    'Spesialister på digital produktutvikling og et litt annerledes konsulentselskap i Oslo. Designere og teknologer som lager bra greier for kundene våre og oss selv.',
  navLinks: [
    { url: '/digital-produktutvikling', title: 'om oss' },
    { url: '/prosjekter', title: 'portfolio' },
    { url: '/jobb', title: 'jobbe i Blank' },
    { url: '/menneskene', title: 'menneskene' },
    { url: '/handboka', title: 'håndbok' },
  ],
  contactLinkTitle: 'kontakt',
  hero: {
    header: `Alt er en test`,
    intro: `I Blank dyrker vi åpenhet, tillit og fellesskap fordi vi mener at det gjør oss til norges beste konsulentselskap for kundene våre, men først og fremst for de som jobber her.
`,
    pictureStack: [
      c.file(
        '/public/images/154770d74e04ec7a17f58a1c031a6430b586d1b8-4000x6000.webp',
        {
          width: 4000,
          height: 6000,
          sha256:
            'cb7108dca3c0a6941084af09d9dc6110b6ffad62facbf134d4f062d9bc12870c',
          mimeType: 'image/webp',
        }
      ),
      c.file(
        '/public/images/f5fc7024f865385152aaf9abe210937a22b8fbbc-689x460.webp',
        {
          width: 689,
          height: 460,
          sha256:
            '5671e05a8cbe0f911b3944931bb627969657cf3b14960b48bbf4417715653c03',
          mimeType: 'image/webp',
        }
      ),
      c.file(
        '/public/images/ce9866c87bf208c47f6ff022ea1109a848dfe1d3-960x795.webp',
        {
          width: 960,
          height: 795,
          sha256:
            '9e2146333b25bad4186c58cf3ed23793f91d86268ff72e0aa6e82fd10f959ba9',
          mimeType: 'image/webp',
        }
      ),
      c.file(
        '/public/images/bd513a984361651b07d1a763878d7634e1e7e17f-5158x3443.webp',
        {
          width: 5158,
          height: 3443,
          sha256:
            '1c9c613483497ca7584be77d9d80011f421609eded1d9ee812fb0041535277dc',
          mimeType: 'image/webp',
        }
      ),
      c.file(
        '/public/images/e9aa35b473381bb84a2ca9e60cee77ea002696e4-700x403.webp',
        {
          width: 700,
          height: 403,
          sha256:
            '224634ba3366ce0efb41cede5540589af9c5da7cf8d0c92fdc493f33a84513b2',
          mimeType: 'image/webp',
        }
      ),
    ],
  },
});
