import Link from 'next/link';
import DarksideToggle from '../DarksideToggle/DarksideToggle';
import SvgIllustration from '../Illustration/SvgIllustration';
import { BlankLogo } from '../SVGs';

interface HeaderProps {
  forceDark?: boolean;
  setFixed?: boolean;
  useAlternativeHeader?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

const Header: React.FC<HeaderProps> = ({
  children,
  forceDark,
  setFixed = true,
  useAlternativeHeader = false,
}) => {
  const fixedHeader = setFixed ? 'fixed z-20 top-0' : '';
  return (
    <div
      className={`flex items-center justify-between w-full h-28 px-[20px] laptop:px-32 pointer-events-none ${fixedHeader} overflow-x-clip ${
        forceDark && 'bg-blankYellow'
      }`}
    >
      {useAlternativeHeader ? (
        <>
          <div className="pointer-events-auto scale-[0.65] tablet:scale-100 laptop:scale-100 origin-[0%_50%] h-[69px] w-[145px] animate-logo" />
          <DarksideToggle />
        </>
      ) : (
        <>
          <Link
            href="/"
            className="hover:bg-transparent pointer-events-auto"
            aria-label="Hjem til Blank.no"
          >
            <SvgIllustration>
              <BlankLogo className="w-[92px] h-[28px] tablet:w-[144px] tablet:h-[44px] laptop:w-[144px] laptop:h-[44px]" />
            </SvgIllustration>
          </Link>
          {children}
        </>
      )}
    </div>
  );
};

export default Header;
