import Link from 'next/link';
import React from 'react';

export interface BlankLinkProps {
  href?: string;
  newTab?: boolean;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  onKeyDown?: () => void;
  role?: string;
  link?: boolean;
  isSelected?: boolean;
}

const BlankLink: React.FC<BlankLinkProps> = ({
  href,
  newTab = false,
  className,
  children,
  onClick,
  onKeyDown,
  role,
  link,
  isSelected = false,
}) => {
  const linkStyle =
    'hover:bg-[#0000FF] hover:text-[#FFFCB6] underline hover:underline py-[3px]';
  return link ? (
    <div className={`${isSelected && 'bg-blankBlack transition-bg-1s'}`}>
      <div className={`${className}`}>
        <Link href={href} className={`${linkStyle}`}>
          {children}
        </Link>
      </div>
    </div>
  ) : (
    <div className={`${isSelected && 'bg-blankBlack transition-bg-1s'}`}>
      <div className={`${className}`}>
        <a
          href={href}
          className={`${linkStyle}`}
          target={`${newTab ? '_blank' : '_self'}`}
          rel="noopener noreferrer"
          onClick={() => !!onClick && onClick()}
          onKeyDown={() => !!onKeyDown && onKeyDown()}
          role={role}
        >
          {children}
        </a>
      </div>
    </div>
  );
};

export default BlankLink;
