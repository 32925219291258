'use client';
import { useEffect, useState } from 'react';

export type Theme =
  | 'light'
  | 'dark'
  | 'alternatingStartLight'
  | 'alternatingStartDark';

const setTheme = (theme: Theme) => {
  const elem = document.getElementById('root');
  if (elem) {
    if (theme === 'dark') {
      elem.style.setProperty('--light', '#272D2A');
      elem.style.setProperty('--dark', '#FFFCB6');
      elem.style.setProperty('--arrow', "url('/icons/arrow.svg')");
    } else {
      elem.style.setProperty('--light', '#FFFCB6');
      elem.style.setProperty('--dark', '#272D2A');
      elem.style.setProperty('--arrow', "url('/icons/arrowDark.svg')");
    }
  }
};

const resetTheme = (theme: string) => {
  if (['dark', 'alternatingStartDark'].includes(theme)) {
    setTheme('light');
  }
};

export const useTheme = (
  theme: Theme,
  id: string,
  isDarksideActive?: boolean
) => {
  const [val, setVal] = useState(false);
  useEffect(() => {
    var scrollTimer = null;
    var isScrolling = false;

    // Ensure no transition on page load
    setTimeout(() => {
      document.getElementById(id).classList.remove('no-transition-on-load');
    }, 10);

    let themes: Theme[] = ['alternatingStartLight', 'light'].includes(theme)
      ? ['light', 'dark']
      : ['dark', 'light'];

    if (['dark', 'alternatingStartDark'].includes(theme)) {
      setTheme(themes[0]);
    }

    if (!['light', 'dark'].includes(theme)) {
      const onScroll = () => {
        if (scrollTimer !== null) {
          clearTimeout(scrollTimer);
        }

        if (!isScrolling) {
          isScrolling = true;
          document
            .getElementById('root')
            .style.setProperty('--short-duration', '50ms');
          document
            .getElementById('root')
            .style.setProperty('--long-duration', '1s');
          document.getElementById('root').style.setProperty('--delay', '200ms');
        }

        if (
          window.scrollY + window.innerHeight / 2 <
          document.body.scrollHeight / 2
        ) {
          setTheme(themes[0]);
        } else {
          setTheme(themes[1]);
        }

        scrollTimer = setTimeout(() => {
          isScrolling = false;
          document
            .getElementById('root')
            .style.setProperty('--short-duration', '0ms');
          document
            .getElementById('root')
            .style.setProperty('--long-duration', '0s');
          document.getElementById('root').style.setProperty('--delay', '0ms');
        }, 150);
      };

      if (isDarksideActive) {
        window.removeEventListener('scroll', onScroll);
        resetTheme(theme);
      } else {
        window.addEventListener('scroll', onScroll, { passive: true });
      }

      setVal(true);

      return () => {
        window.removeEventListener('scroll', onScroll);
        resetTheme(theme);
      };
    }

    return () => {
      resetTheme(theme);
    };
  }, [isDarksideActive, id, theme]);

  return val;
};
