import { c, s, t } from '../val.config';

const footerSectionSchema = s.object({
  header: s.string(),
  items: s.array(
    s.object({
      text: s.string(),
      href: s.string().raw().nullable(),
      newTab: s.boolean(),
      divider: s.boolean(),
    })
  ),
});
const footerSchema = s.object({
  main: footerSectionSchema,
  sections: s.array(footerSectionSchema),
});

export type Footer = t.inferSchema<typeof footerSchema>;

export default c.define('/content/footer.val.ts', footerSchema, {
  main: {
    header: 'Snakke med oss',
    items: [
      {
        text: 'Snakk med salg!',
        href: '/prosjektprat',
        newTab: false,
        divider: false,
      },
      {
        text: 'Ta en prat om jobb',
        href: '/kaffeprat',
        newTab: false,
        divider: true,
      },
      {
        text: 'innboks@blank.no',
        href: 'mailto:innboks@blank.no',
        newTab: false,
        divider: false,
      },
      {
        text: '22 20 40 00',
        href: 'tel:22204000',
        newTab: false,
        divider: false,
      },
    ],
  },
  sections: [
    {
      items: [
        {
          href: 'https://www.blank.no/handboka',
          text: 'Håndboka',
          newTab: false,
          divider: false,
        },
      ],
      header: 'Mer om oss',
    },
    {
      header: 'Følg oss',
      items: [
        {
          text: 'Håndboka',
          href: '/handboka',
          newTab: false,
          divider: false,
        },
        {
          text: 'Blogg',
          href: 'https://blogg.blank.no/',
          newTab: true,
          divider: false,
        },
        {
          text: 'Facebook',
          href: 'https://www.facebook.com/blankoslo',
          newTab: true,
          divider: false,
        },
        {
          text: 'Instagram',
          href: 'https://www.instagram.com/blankoslo/',
          newTab: true,
          divider: false,
        },
        {
          text: 'LinkedIn',
          href: 'https://no.linkedin.com/company/blankas',
          newTab: true,
          divider: false,
        },
        {
          text: 'Kjøregår',
          href: '/events',
          newTab: false,
          divider: false,
        },
      ],
    },
    {
      header: 'Finn oss',
      items: [
        {
          text: 'Blank AS',
          href: null,
          newTab: false,
          divider: false,
        },
        {
          text: 'Torggata 15',
          href: 'https://goo.gl/maps/7nU5pofgdSm',
          newTab: true,
          divider: false,
        },
        {
          text: '0181 Oslo',
          href: null,
          newTab: false,
          divider: false,
        },
        {
          text: 'Org. nr: 915 433 073',
          href: null,
          newTab: false,
          divider: false,
        },
      ],
    },
  ],
});
