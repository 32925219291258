import React, { FC, Fragment } from 'react';
import BlankLink from '../BlankLink/BlankLink';
import { AI, BlankRectangle } from '../SVGs';
import AISticker from '../SVGs/Icons/AISticker';
import classNames from 'classnames';
import { useVal } from '../../val/val.client';
import footerVal from '../../content/footer.val';

interface FooterProps {
  renderSticker?: boolean;
}

const Footer: FC<FooterProps> = ({ renderSticker = false }) => {
  const footerContent = useVal(footerVal);
  return (
    <div className="flex flex-col items-start bg-blankYellow pb-[134px] transition-bg-1s pl-32 laptop:pt-[130px] resizable-viewport laptop:h-[calc(100vh-var(--header-height)-60px)]">
      <div className="mb-80 laptop:hidden">
        <BlankRectangle />
      </div>
      <div className="flex flex-row justify-between w-full overflow-x-clip">
        <div className="flex flex-col gap-14 children:flex children:flex-col children:gap-1">
          <div id="contactUs" className="scroll-m-20 laptop:scroll-m-0">
            <FooterHeader>{footerContent.main.header}</FooterHeader>
            {footerContent.main.items.map((item, idx) => (
              <Fragment key={idx}>
                <FooterItem
                  href={item.href}
                  newTab={item.newTab}
                  noLink={!item.href}
                >
                  {item.text}
                </FooterItem>
                {item.divider && <div className="mb-4"></div>}
              </Fragment>
            ))}
          </div>

          {footerContent.sections.map((section, idx) => (
            <div key={idx}>
              <FooterHeader>{section.header}</FooterHeader>
              {section.items.map((item, idx) => (
                <Fragment key={idx}>
                  <FooterItem
                    href={item.href}
                    newTab={item.newTab}
                    noLink={!item.href}
                  >
                    {item.text}
                  </FooterItem>
                  {item.divider && <div className="mb-4"></div>}
                </Fragment>
              ))}
            </div>
          ))}
          <div className="mb-52"></div>
        </div>
        {renderSticker && (
          <div className="self-end pr-32">
            <AISticker />
          </div>
        )}
      </div>
    </div>
  );
};
interface FooterItemProps {
  children: React.ReactNode;
  href: string;
  newTab?: boolean;
  noLink?: boolean;
  className?: string;
}

interface FooterHeaderProps {
  children: React.ReactNode;
  className?: string;
}

const FooterHeader = ({ children, className }: FooterHeaderProps) => {
  const textStyle =
    'font-plakat text-blankBlack uppercase text-3xl leading-blankTight tracking-blankTighter opacity-90 transition-50ms';
  const itemStyle = `flex flex-col content-start mb-4 ${className} w-fit ${textStyle}`;

  return <div className={classNames(itemStyle)}>{children}</div>;
};

const FooterItem = ({
  children,
  href,
  newTab = false,
  noLink = false,
  className,
}: FooterItemProps) => {
  const textStyle =
    'text-[22px] leading-blankLoose tracking-wide text-blankBlack opacity-90 transition-50ms font-[200]';
  const itemStyle = `flex flex-col content-start w-fit`;

  return noLink ? (
    <div className={classNames(itemStyle, className, textStyle, '')}>
      {children}
    </div>
  ) : (
    <div className={classNames(itemStyle, className, textStyle)}>
      <BlankLink newTab={newTab} href={href}>
        {children}
      </BlankLink>
    </div>
  );
};

export default Footer;
