'use client';
import { useEffect } from 'react';

// declare global {
//   interface Window {
//     plausible: (event: string, options?: any) => void;
//   }
// }

export const useViewportSizeTracking = () => {
  useEffect(() => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    // TODO: Figure out why the website crashes with this command.
    // TypeError: 'plausible' is not a function on 'window'
    // window.plausible('Viewport size', {
    //   props: {
    //     size: { width, height },
    //   },
    // });
  }, []);
};
