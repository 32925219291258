import { SanityImageSource } from '@sanity/asset-utils';
import Head from 'next/head';
import { builder } from '../../utils/sanityUtils';
import { Image } from '@valbuild/next';

interface MetadataProps {
  /**
   * The title of the page as it should appear within the graph, e.g., "The
   * Rock".
   */
  title: string;
  /**
   * A one to two sentence description of the page.
   */
  description: string;
  /**
   * The canonical URL of the page that will be used as its permanent ID in the
   * graph, e.g., "https://www.imdb.com/title/tt0117500/".
   */
  url: string;
  /**
   * An image URL which should represent the page within the graph. The image
   * should have an aspect ratio of 1.91:1 and minimum size 1200x628.
   */
  image?: string | Image;
  /**
   * The index page will have a custom title
   */
  isIndex?: boolean;
}

const PageMetadata = ({
  title,
  description,
  url,
  image = '/images/share-generic.png',
  isIndex = false,
}: MetadataProps): JSX.Element => {
  let imageUrl: string;
  if (typeof image === 'string') {
    imageUrl = image;
  } else {
    // This scales the image to the minimum dimensions compatible with LinkedIn,
    // Facebook and Twitter. All platforms recommend an aspect ratio of 1.91:1.
    // The aspect ratio is achieved by cropping.
    imageUrl = image.url;
  }
  return (
    <Head>
      <title>{isIndex ? `Blank | ${title}` : `${title} | Blank`}</title>
      <meta name="description" content={description} />
      {url && <meta property="og:url" content={url} />}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Blank" />
      <meta property="og:locale" content="nb" />
      <meta property="og:image" content={imageUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="blank.no" />
      {url && <meta property="twitter:url" content={url} />}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="@blankoslo" />
      <meta name="twitter:image" content={imageUrl} />
    </Head>
  );
};

export default PageMetadata;
