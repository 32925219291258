import Head from 'next/head';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import NavLink from '../components/NavLink/NavLink';
import PageMetadata from '../components/PageMetadata/PageMetadata';
import StickyOverlay from '../containers/StickyOverlay';
import { useFriendlyViewPort } from '../hooks/useFriendlyViewPort';
import { useTheme } from '../hooks/useTheme';
import { useViewportSizeTracking } from '../hooks/useViewportSizeTracking';
import { useVal } from '../val/val.client';
import homePageVal from '../content/pages/home.val';

const Index = () => {
  const {
    theme,
    title,
    metadataTitle,
    metadataDescription,
    navLinks,
    contactLinkTitle,
  } = useVal(homePageVal);
  useFriendlyViewPort();
  useViewportSizeTracking();
  useTheme(theme, 'frontPage');

  const textStyle =
    'text-blankBlack text-2xl tablet:text-3xl laptop:text-fluid laptop:min-h-[0vh] leading-blankTight tracking-blankTight opacity-90 transition-50ms';

  return (
    <>
      <div id="frontPage" className="bg-[#272D2A]">
        <PageMetadata
          title={metadataTitle}
          description={metadataDescription}
          url="/"
          isIndex
        />
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          ></meta>
          <style>
            *
            {`{
            --light: ${'#272D2A'}; 
            --dark: ${'#FFFCB6'};
          }`}
          </style>
        </Head>
        <Header useAlternativeHeader />
        <StickyOverlay>
          <div className="laptop:h-[calc(100vh+192px)]">
            <div className="relative h-screen">
              <div className="flex flex-col justify-between h-full px-24 laptop:flex-row-reverse">
                <div className="flex flex-col items-end py-32 mt-headerHeight laptop:mt-0 laptop:top-headerHeight laptop:sticky laptop:self-center laptop:py-0">
                  {navLinks.map((link) => (
                    <NavLink key={link.url} href={link.url}>
                      {link.title}
                    </NavLink>
                  ))}
                  <NavLink
                    className="underline hover:text-[#fffcb6]"
                    onClick={() => {
                      void 1;
                      document.getElementById('contactUs')?.scrollIntoView();
                    }}
                  >
                    {contactLinkTitle}
                  </NavLink>
                </div>
                <h1
                  className={`${textStyle} text-left laptop:text-center mb-[128px] laptop:mb-0 max-w-[400px] laptop:max-w-none laptop:absolute laptop:left-6 laptop:top-1/2 laptop:-translate-y-1/2`}
                >
                  {title}
                </h1>
              </div>
            </div>
          </div>
        </StickyOverlay>
        <Footer />
      </div>
    </>
  );
};

export default Index;
