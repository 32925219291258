import Link from 'next/link';
import { val } from '../../val.config';
import { ValEncodedString } from '@valbuild/next';

interface NavLinkProps {
  className?: string;
  href?: string;
  linkStyle?: string;
  onClick?: () => void;
  outgoing?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

const NavLink: React.FC<NavLinkProps> = ({
  children,
  className,
  href,
  onClick,
  outgoing,
}) => {
  const classes = `font-respira leading-blankSnug tracking-blankTight text-blankBlack mb-8 last:mb-0 text-[31px] tablet:text-[41px] laptop:text-[51px] hover:bg-blankBlueSecondary hover:underline w-fit ${className}`;

  return href ? (
    outgoing ? (
      <a
        className={classes}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    ) : (
      <Link
        href={val.raw(href as ValEncodedString)}
        className={classes}
        {...(typeof children === 'string' ? val.attrs(children) : {})}
      >
        {typeof children === 'string'
          ? val.raw(children as ValEncodedString)
          : children}
      </Link>
    )
  ) : (
    <button className={classes} onClick={onClick}>
      {children}
    </button>
  );
};

export default NavLink;
